
import { Component, Vue, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FarmingList, FormList, JobContentList } from '@/types/farmingCalendar'
import { DicInfo } from '@/types/common'

@Component({

})
export default class Guide extends Vue {
    loading = false
    tableData: FarmingList[] = []

    farmingTypeData: Array<DicInfo> = []
    typeLoading = false
    isAddFarmingType: number | null = null

    titleDig = '查看农事指导'
    isEdit = true
    dialogVisible2 = false
    jobLoading = false

    @Ref('form') readonly form!: ElForm
    formData: FormList = {
      templateCalendarId: '',
      farmingExplain: '',
      yhFarmingJobTemplateList: []
    }

    rules = {
      templateCalendarId: [
        { required: true, message: '请选择作业月份', trigger: 'blur' }
      ]
    }

    created () {
      this.getTableData()
      this.getFarmingType()
    }

    // 获取列表数据
    getTableData () {
      this.loading = true
      this.$axios.get<{ list: Array<FarmingList> }>(this.$apis.calendar.selectYhFarmingCalendarTemplateByPage).then(res => {
        this.tableData = res.list || []
      }).finally(() => {
        this.loading = false
      })
    }

    // 列表为空初始化列表
    showList () {
      this.$axios.post(this.$apis.calendar.addAllCalendarTemplate).then(() => {
        this.getTableData()
      })
    }

    // 获取农事类型列表
    getFarmingType () {
      this.typeLoading = true
      this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'farmingType' }).then(res => {
        this.farmingTypeData = res.farmingType || []
      }).finally(() => {
        this.typeLoading = false
      })
    }

    // 查看农事指导
    detailShow (row: FarmingList) {
      this.formData.templateCalendarId = row.templateCalendarId
      this.titleDig = '查看农事指导'
      this.isEdit = false
      this.dialogVisible2 = true
      this.getFarmingDetail()
    }

    // 编辑农事指导
    editShow (row: FarmingList) {
      this.titleDig = '编辑农事指导'
      this.formData.templateCalendarId = row.templateCalendarId
      this.isEdit = true
      this.dialogVisible2 = true
      this.getFarmingDetail()
    }

    // 获取农事指导详情
    getFarmingDetail () {
      this.jobLoading = true
      this.$axios.get<JobContentList>(this.$apis.calendar.selectYhFarmingCalendarTemplate, { templateCalendarId: this.formData.templateCalendarId }).then(res => {
        this.formData = res || []
        this.formData.farmingExplain = res.farmingExplain || ''
        this.formData.yhFarmingJobTemplateList = res.yhFarmingJobTemplateList || []
      }).finally(() => {
        this.jobLoading = false
      })
    }

    // 新增作业内容
    addFarmingContent () {
      this.formData.yhFarmingJobTemplateList.push({
        farmingType: '',
        farmingTypeName: '',
        deleteStatus: '1', // 是否可删除 1 可删除
        content: '',
        isInput: false
      })
    }

    // 删除作业内容
    deleteFarmingList (index: number) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.yhFarmingJobTemplateList.splice(index, 1)
      })
    }

    // 编辑农事指导确认
    submit () {
      const form = JSON.parse(JSON.stringify(this.formData)) as FormList
      this.form.validate(valid => {
        if (valid) {
          for (let i = 0; i < form.yhFarmingJobTemplateList.length; i++) {
            delete form.yhFarmingJobTemplateList[i].isInput
            delete form.yhFarmingJobTemplateList[i].farmingTypeName
            if (form.yhFarmingJobTemplateList[i].farmingType === '') {
              this.$message.warning('存在农事类型必填字段未录入')
              return
            }
          }
          this.$axios.post(this.$apis.calendar.updateYhFarmingCalendarTemplate, form).then(() => {
            this.dialogVisible2 = false
            this.getTableData()
          })
        }
      })
    }
}
